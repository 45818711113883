export class CreateOrganization {
  static getAttributeTypeMap() {
    return CreateOrganization.attributeTypeMap;
  }
  constructor(e, a, t, n, o, i, r, s, m, y, p, g, h, b, f, c, C, N, l) {
    this.name = e, this.organizationType = a, this.nameAlias = t, this.organizationAlias = n, this.email = o, this.eori = i, this.vatNumber = r, this.phoneCountryCode = s, this.phone = m, this.faxCountryCode = y, this.fax = p, this.website = g, this.contactPhoneCountryCode = h, this.contactPhone = b, this.legalEntityType = f, this.note = c, this.tags = C, this.systemGenerated = N, this.scac = l;
  }
}
CreateOrganization.discriminator = void 0, CreateOrganization.attributeTypeMap = [{
  name: "name",
  baseName: "name",
  type: "string",
  format: ""
}, {
  name: "organizationType",
  baseName: "organizationType",
  type: "Array<string>",
  format: ""
}, {
  name: "nameAlias",
  baseName: "nameAlias",
  type: "string",
  format: ""
}, {
  name: "organizationAlias",
  baseName: "organizationAlias",
  type: "string",
  format: ""
}, {
  name: "email",
  baseName: "email",
  type: "string",
  format: ""
}, {
  name: "eori",
  baseName: "eori",
  type: "string",
  format: ""
}, {
  name: "vatNumber",
  baseName: "vatNumber",
  type: "string",
  format: ""
}, {
  name: "phoneCountryCode",
  baseName: "phoneCountryCode",
  type: "string",
  format: ""
}, {
  name: "phone",
  baseName: "phone",
  type: "string",
  format: ""
}, {
  name: "faxCountryCode",
  baseName: "faxCountryCode",
  type: "string",
  format: ""
}, {
  name: "fax",
  baseName: "fax",
  type: "string",
  format: ""
}, {
  name: "website",
  baseName: "website",
  type: "string",
  format: ""
}, {
  name: "contactPhoneCountryCode",
  baseName: "contactPhoneCountryCode",
  type: "string",
  format: ""
}, {
  name: "contactPhone",
  baseName: "contactPhone",
  type: "string",
  format: ""
}, {
  name: "legalEntityType",
  baseName: "legalEntityType",
  type: "string",
  format: ""
}, {
  name: "note",
  baseName: "note",
  type: "string",
  format: ""
}, {
  name: "tags",
  baseName: "tags",
  type: "Array<string>",
  format: ""
}, {
  name: "systemGenerated",
  baseName: "systemGenerated",
  type: "boolean",
  format: ""
}, {
  name: "scac",
  baseName: "scac",
  type: "string",
  format: ""
}];